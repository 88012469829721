<template>
  <header class="_header mb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 text-center">Инструмент для магазина</div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped lang="scss">
</style>