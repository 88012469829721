<template>
  <footer class="_footer mt-5 py-1">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 mb-2 text-center text-md-start">
          <input id="save_default" type="checkbox" name="save" value="0">
          <input id="save" type="checkbox" name="save" value="1" v-model="save_it">
          <label for="save">Сохранять изменения</label>
        </div>
        <div v-if="types.length > 0" class="col-sm-6 mb-2">
          <small class="d-none d-sm-inline">Единицы измерения:</small>
          <small class="d-inline d-sm-none ">Ед. изм:</small> &nbsp;
          <div v-for="type in types" :key="type.id" class="d-inline">
            <div class="badge mx-1"
                 :class="[type.id === currentType.id ? 'default-cursor text-bg-primary' :  'pointer-cursor text-bg-secondary']"
                 @click="$emit('update:type', type.id)"
            >{{ type.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "Footer",
  props: {
    types: Object,
    currentType: Object,
  }
}
</script>

<style scoped lang="scss">

</style>