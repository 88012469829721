<template>
  <div v-if="this.unloaded" id="preloader"><div id="spinner"></div></div>

  <Header></Header>

  <main class="_content">
    <div class="container">

      <div v-for="row in rows" :key="row.id" class="row align-items-center mb-3">
        <div class="col-2 col-md-1 mb-2 text-center"><button class="btn btn-danger btn-sm" @click="removeRow(row.id)"><i class="fa fa-fw fa-trash"></i></button></div>
        <div class="col-10 col-md-4 mb-2"><label>Наименование<input v-model="row.name"></label></div>
        <div class="col-4 col-md-2 mb-2" @keyup="countPrice(row)" @keypress="checkInput($event)"><label>Цена<input v-model="row.price" placeholder="0"></label></div>
        <div class="col-4 col-md-2 mb-2" @keyup="countPrice(row)" @keypress="checkInput($event)"><label>Вес, {{ types.find((type) => type.id === currentType.id).name }}<input v-model="row.capacity" placeholder="0"></label></div>
        <div class="col-4 col-md-3 mb-2"><label class="readonly">Стоимость<input v-model="row.total" readonly></label></div>
      </div>

      <div class="row mt-3 justify-content-center">
        <div class="col-md-3">
          <button @click="addRow" class="btn btn-primary btn-sm w-100"><i class="fa fa-fw fa-plus"></i> Добавить</button>
        </div>
      </div>

    </div>
  </main>

  <Footer :types="types" :currentType="currentType" @update:type="changeType"></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: { Header, Footer },
  emits: ['update:type'],
  data() {
    return {
      unloaded: true,
      types: [
        {
          id: 1,
          name: 'кг',
          multiplier: 1,
          transform: .001,
        },
        {
          id: 2,
          name: 'гр',
          multiplier: 1000,
          transform: 1000,
        },
      ],
      currentType: {},
      defaultTypeId: 2,
      count: 1,
      rows: [
        {
          id: 1,
          name: 'Товар 1',
          price: '',
          capacity: '',
          total: '...',
        }
      ],
    }
  },
  created() {
    this.setCurrentType()
  },
  mounted() {
    window.addEventListener('load', () => this.unloaded = false)
  },
  methods: {
    setCurrentType() {
      this.currentType = this.types.find((type) => this.defaultTypeId === type.id)
    },
    countPrice(row, change) {
      let updateWeight = undefined !== change && change;

      if (!this.isEmpty(row.price) && !this.isEmpty(row.capacity)) {
        if (updateWeight) row.capacity = +(''+row.capacity).replace(',','.') * this.currentType.transform;

        row.total = ((row.price / row.capacity) * this.currentType.multiplier).toFixed(2);
      } else {
        row.total = '...';
      }

      return row;
    },
    checkInput(event) {
      if (0 !== event.key.search(/[0-9.]/) || ('' === event.target.value && event.key === '.')) event.preventDefault();
    },
    addRow() {
      let id =  ++this.count;

      this.rows.push({
        id: id,
        name: 'Товар '+ id,
        price: '',
        capacity: '',
        total: '...',
      });
    },
    removeRow(id) {
      this.rows.forEach((item, index) => (item.id === id) ? this.rows.splice(index, 1) : null);
    },

    isEmpty (str) {
      return !str || str.length === 0
    },

    changeType(typeId) {
      if (typeId === this.currentType.id) return false;

      this.currentType = this.types.find((type) => type.id === typeId);
      this.rows.forEach((row) => this.countPrice(row, true));
    },

  }
}
</script>

<style lang="scss">
#preloader {
  background: #FFF;
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  transition: opacity .3s;
  width: 100%;
  z-index: 10000 }

@keyframes spin {
  0% { opacity: 0; transform: rotate(0deg); }
  50% { opacity: 1; transform: rotate(180deg); }
  100% { opacity: 0; transform: rotate(360deg); }
}

#spinner {
  animation: spin 2s linear infinite;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0d6efd;
  border-radius: 50%;
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px; }
@import "vendor/scss/normalize";

/* Vendor */
@import "vendor/scss/bootstrap";
@import "vendor/scss/fontawesome";

/* Project */
@import "scss/variables";

@import "scss/utils";
@import "scss/fonts";
@import "scss/tags";

@import "scss/header";
@import "scss/nav";
@import "scss/content.scss";
@import "scss/footer";

@import "scss/print";

</style>
